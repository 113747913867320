<script setup lang="ts">
const props = defineProps({
  error: Object,
})
const handleError = () => clearError({ redirect: '/' })
</script>

<template lang="pug">
div(class="flex flex-col w-full min-h-screen justify-center items-center")
  div(class="p-24 max-w-screen-sm")
    h1
      | Sorry, there was a&nbsp;problem loading the&nbsp;page.
    hr(class="ml-0")
    pre(v-if="props.error" class="hidden") {{ props.error }}
    p Nothing was found at #[span(class="font-mono") {{ props.error?.url || 'this URL.' }}]
    button(class="button button--theme-blue-dark button--md" @click="handleError") Return to site
</template>
